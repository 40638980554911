// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 700px) {
    header div:last-child {
        display: inline-block;
    }
    .dropdown {
        display: inline-block;
        position: relative;
    }
    button {
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 18px;
        cursor: pointer;
    }
    .dropdown-options {
        display: none;
        position: absolute;
        overflow: auto;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
    }
    .dropdown-options a {
        display: block;
        color: #000000;
        /* padding: 5px; */
        text-decoration: none;
        box-shadow: 0px 5px 5px 0px rgba(60, 197, 231, 0.4);
        padding: 10px 20px;
    }
    .navigation{
        display: none;
    }

    /* .header-image img{
        height: auto;
    } */
    .header-image-laptop {
        display: none;
    }
}

@media (min-width: 700px) {
    .dropdown {
        display: none;
    }
    .navigation {
        /* flex: 1; */
        text-align: center; 
        display: flex;
    }
    .header-image-laptop {
        padding-left: 3%;
    }

    .header-image-mobile{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;IACI;QACI,qBAAqB;IACzB;IACA;QACI,qBAAqB;QACrB,kBAAkB;IACtB;IACA;QACI,YAAY;QACZ,kBAAkB;QAClB,kBAAkB;QAClB,eAAe;QACf,eAAe;IACnB;IACA;QACI,aAAa;QACb,kBAAkB;QAClB,cAAc;QACd,sBAAsB;QACtB,kBAAkB;QAClB,gDAAgD;IACpD;IACA;QACI,cAAc;QACd,cAAc;QACd,kBAAkB;QAClB,qBAAqB;QACrB,mDAAmD;QACnD,kBAAkB;IACtB;IACA;QACI,aAAa;IACjB;;IAEA;;OAEG;IACH;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;QACb,kBAAkB;QAClB,aAAa;IACjB;IACA;QACI,gBAAgB;IACpB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media (max-width: 700px) {\n    header div:last-child {\n        display: inline-block;\n    }\n    .dropdown {\n        display: inline-block;\n        position: relative;\n    }\n    button {\n        border: none;\n        border-radius: 5px;\n        padding: 10px 20px;\n        font-size: 18px;\n        cursor: pointer;\n    }\n    .dropdown-options {\n        display: none;\n        position: absolute;\n        overflow: auto;\n        background-color: #fff;\n        border-radius: 5px;\n        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);\n    }\n    .dropdown-options a {\n        display: block;\n        color: #000000;\n        /* padding: 5px; */\n        text-decoration: none;\n        box-shadow: 0px 5px 5px 0px rgba(60, 197, 231, 0.4);\n        padding: 10px 20px;\n    }\n    .navigation{\n        display: none;\n    }\n\n    /* .header-image img{\n        height: auto;\n    } */\n    .header-image-laptop {\n        display: none;\n    }\n}\n\n@media (min-width: 700px) {\n    .dropdown {\n        display: none;\n    }\n    .navigation {\n        /* flex: 1; */\n        text-align: center; \n        display: flex;\n    }\n    .header-image-laptop {\n        padding-left: 3%;\n    }\n\n    .header-image-mobile{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
