import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import MissionVision from './MissionVision';
import LeadershipTeam from './LeadershipTeam';

const App = () => {
  return (
    <Router>
      <div className="scroll-container">
        <Header />
        <Routes>
          <Route path="/mission-vision" element={<MissionVision />} />
          <Route path="/leadership-team" element={<LeadershipTeam />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router> 
  );
}

export default App; 