import React from 'react';
import missionImage from './assests/mission-vission/5.gif'; 
import './MissionVision.css'; 

const MissionVision = () => {
  return (
    <div className="scroll-container" style={{backgroundColor: 'white',textAlign: 'center' }}>
      <section className="MissionVision-Section" style={{ textAlign: 'center', marginBottom: '5px', position: 'relative' }}>
        <img src={missionImage} alt="MissionVision" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
    </div>
  );
}

export default MissionVision;
