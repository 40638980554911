// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-image {
  border-radius: 0px;
  /* Adjust the value as needed */
}
`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+BAA+B;AACjC","sourcesContent":[".home-image {\n  border-radius: 0px;\n  /* Adjust the value as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
