// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-border {
  border-radius: 10px;
}

.scroll-container {
  overflow: hidden;
}

.image-section {
  padding-bottom: 5%;
  padding-top: 2%;
}`, "",{"version":3,"sources":["webpack://./src/LeadershipTeam.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".rounded-border {\n  border-radius: 10px;\n}\n\n.scroll-container {\n  overflow: hidden;\n}\n\n.image-section {\n  padding-bottom: 5%;\n  padding-top: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
