import React from 'react';
import footerLogo from './footerLogo.png';
import './index.css';

const Footer = () => {
  return (
    <>
      <footer className='footer-laptop' style={{ backgroundColor: '#001C40', width: '100%',alignItems: 'center', justifyContent: 'space-between', fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', padding: '15px 0', borderTop: '1px solid #ccc', marginTop: '20px', border: '1px solid #ccc', borderRadius: '10px', color: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10%' }}>
          <img src={footerLogo} alt="Footer Logo" style={{ height: '75px', marginRight: '20px' }} />
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <p>&copy; 2024 Certainti.Ai. All rights reserved.</p>
        </div>
        <div style={{ paddingRight: '10%' }}>
          <p><b> Contact Us </b></p>
          <p>Email: info@certainti.ai</p>
          <p>Phone: +1 (647) 991-4991</p>
        </div>
      </footer>

      <footer className='footer-mobile' style={{ backgroundColor: '#001C40', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', padding: '15px 0', borderTop: '1px solid #ccc', marginTop: '20px', border: '1px solid #ccc', borderRadius: '10px', color: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10%', marginBottom: '10px' }}>
          <img src={footerLogo} alt="Footer Logo" style={{ height: '50px', marginRight: '10px' }} />
          <p>&copy; 2024 Certainti.Ai. All rights reserved.</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', paddingBottom: '10px' }}>
          <p><b> Contact Us </b></p>
          <p>Email: info@certainti.ai</p>
          <p>Phone: +1 (647) 991-4991</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
