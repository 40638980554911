import React from 'react';
import section1Image from './assests/home/1.gif';
import section2Image from './assests/home/2.gif';
import section3Image from './assests/home/3.gif';
import section4Image from './assests/home/4.gif';
import './Home.css';

const Home = () => {
  return (
    <div style={{ backgroundColor: 'white' ,textAlign: 'center' }} className="scroll-container">
      <section className="home-section" style={{ textAlign: 'center', marginBottom: '5px', position: 'relative' }}>
        <img src={section1Image} alt="Section 1" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className="home-section" style={{ textAlign: 'center', marginBottom: '5px', position: 'relative' }}>
        <img src={section2Image} alt="Section 2" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className="home-section" style={{ textAlign: 'center', position: 'relative' }}>
        <img src={section3Image} alt="Section 3" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className="home-section" style={{ textAlign: 'center', position: 'relative' }}>
        <img src={section4Image} alt="Section 3" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
    </div>
  );
}

export default Home;
