import React from 'react';
import leader1Image from './assests/leaderships/6.png'; 
import leader2Image from './assests/leaderships/7.png'; 
import leader3Image from './assests/leaderships/8.png'; 
import leader4Image from './assests/leaderships/9.png'; 
import leader5Image from './assests/leaderships/10.png'; 
import './LeadershipTeam.css'; 

const LeadershipTeam = () => {
  return (
    <div style={{ backgroundColor: 'white' ,textAlign: 'center' }} className="scroll-container">
      <section className='image-section'>
        <img src={leader1Image} alt="Leader 1" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className='image-section'>
        <img src={leader2Image} alt="Leader 2" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className='image-section'>
        <img src={leader3Image} alt="Leader 3" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className='image-section'>
        <img src={leader4Image} alt="Leader 4" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
      <section className='image-section'>
        <img src={leader5Image} alt="Leader 5" style={{ maxWidth: '75%', height: 'auto' }} />
      </section>
    </div>
  );
}

export default LeadershipTeam;
