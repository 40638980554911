import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./logo.png";
import "./Header.css";

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const Navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowMobileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveMenu("home");
    } else if (location.pathname === "/mission-vision") {
      setActiveMenu("mission-vision");
    } else if (location.pathname === "/leadership-team") {
      setActiveMenu("leadership-team");
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick = (menu, nav) => {
    Navigate(nav);
    setActiveMenu(menu);
    showMobileMenu();
  };

  const showMobileMenu = () => {
    setIsShowMobileMenu(!isShowMobileMenu);
  };

  return (
    <header
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        padding: "0px",
        borderBottom: "1px solid #ccc",
        marginBottom: "5px",
        position: "fixed",
        top: 0,
        backgroundColor: "rgba(255, 255, 255, 1)",
        zIndex: 1000,
        height: isScrolled ? "-15px" : "auto",
        transition: "height 0.3s",
      }}>
      <div class="dropdown">
        <button
          onClick={showMobileMenu}
          style={{
            cursor: "pointer",
            // marginRight: '1px',
            display: "flex",
            transition: "display 0.3s",
          }}>
          ☰
        </button>
        {isShowMobileMenu && (
          <div class="dropdown-options" ref={dropdownRef}>
            <a
              style={{
                background:
                  activeMenu === "home"
                    ? "#09A79E"
                    : "rgba(158, 193, 191, 0.5)",
              }}
              onClick={() => handleMenuClick("home", "/")}>
              Home
            </a>
            <a
              style={{
                background:
                  activeMenu === "mission-vision"
                    ? "#09A79E"
                    : "rgba(158, 193, 191, 0.5)",
              }}
              onClick={() =>
                handleMenuClick("mission-vision", "/mission-vision")
              }>
              Mission & Vision
            </a>
            <a
              style={{
                background:
                  activeMenu === "leadership-team"
                    ? "#09A79E"
                    : "rgba(158, 193, 191, 0.5)",
              }}
              onClick={() =>
                handleMenuClick("leadership-team", "/leadership-team")
              }>
              Leadership Team
            </a>
          </div>
        )}
      </div>
      <div className="header-image-div" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => {
        Navigate("/");
        setActiveMenu("home");
      }}>
        <img
          src={logo}
          alt="Logo"
          className="header-image-laptop"
          style={{ height: isScrolled ? "25px" : "30px", marginRight: "20px" }}
        />
        <img
          src={logo}
          alt="Logo"
          className="header-image-mobile"
          style={{ height: isScrolled ? "15px" : "20px", marginRight: "20px" }}
        />
      </div>
      <nav className="navigation">
        <ul
          style={{
            display: "flex",
            flexDirection: "start",
            listStyleType: "none",
            padding: 0,
            display: "flex",
            justifyContent: "left",
            marginLeft: "25px",
          }}>
          <li
            style={{
              marginRight: "10px",
              cursor: "pointer",
              borderRadius: "20px",
              padding: "10px 20px",
              background:
                activeMenu === "home" ? "#09A79E" : "rgba(158, 193, 191, 0.5)",
              backdropFilter: "blur(10px)",
              fontSize: isScrolled ? "13px" : "15px",
              whiteSpace: "nowrap",
              transition: "font-size 0.3s",
              display: "inline-block",
            }}
            onClick={() => handleMenuClick("home", "/")}>
            Home
          </li>
          <li
            style={{
              marginRight: "10px",
              cursor: "pointer",
              borderRadius: "20px",
              padding: "10px 20px",
              background:
                activeMenu === "mission-vision"
                  ? "#09A79E"
                  : "rgba(158, 193, 191, 0.5)",
              backdropFilter: "blur(10px)",
              fontSize: isScrolled ? "13px" : "15px",
              whiteSpace: "nowrap",
              transition: "font-size 0.3s",
              display: "inline-block",
            }}
            onClick={() =>
              handleMenuClick("mission-vision", "/mission-vision")
            }>
            Vision & Mission
          </li>
          <li
            style={{
              marginRight: "10%",
              cursor: "pointer",
              borderRadius: "20px",
              padding: "10px 20px",
              background:
                activeMenu === "leadership-team"
                  ? "#09A79E"
                  : "rgba(158, 193, 191, 0.5)",
              backdropFilter: "blur(10px)",
              fontSize: isScrolled ? "13px" : "15px",
              whiteSpace: "nowrap",
              transition: "font-size 0.3s",
              display: "inline-block",
            }}
            onClick={() =>
              handleMenuClick("leadership-team", "/leadership-team")
            }>
            Leadership Team
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
